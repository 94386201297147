// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/theme/layout"
import Image from "../components/atoms/image"
import SEO from "../components/organisms/seo"
import styled from "styled-components"
import OtherPeople from "../components/organisms/otherpeople"

const AboutPage: React.FC<PageProps> = ({ data }) => {
  const image = data.images.edges.find(n => {
    return n.node.relativePath.includes("nukii2.jpg")
  })
  const domain = "https://kurkuru-jodo.juggling-pintcle.com"
  return (
    <Layout>
      <SEO
        lang="ja"
        title="ぴんとくるくる浄土 - 貫井洋介"
        description="1995年生まれ。2012年にジャグリングと出会う。ボールジャグラー。過去には、JJF2017 Championship 決勝出場や、Circus without Circle 第7回公演出演など。"
        picUrl={domain + image.node.childImageSharp.sizes.src}
      />
      <Section>
        <TitleMain>貫井洋介</TitleMain>
        <TitleMain>不可能な赤</TitleMain>
        <MainImg filename="nukii2.jpg" />
        <TitleSub>技術の枠組みの向こうに道具の枠組みを見る</TitleSub>
        <TitleDesc>
          ジャグラーはボールを、手のひらをはじめとした身体の様々な部位で扱うことができる。
          <br />
          僕は身体がボールと新たな接触を持つ瞬間を見続けてきて、いつしかそこには４つのボールの扱い方があることを見出した。
          <br />
          <br />
          「乗せる」「転がす」「弾く」「挟む」。 <br /> <br />
          トスジャグリングをするとき、ジャグラーはボールを指で包んで握るという扱い方をするが、これは僕には「指で挟む」という扱い方に見える。
          <br />
          さらに、ボールの種類によって４つの扱い方をしたときの反応が異なる。
          <br />
          大半のボールは「握る＝指で挟む」という扱い方がやりやすいが、径が大きくなってくると「握る」扱い方は難しくなってきて「転がす」扱い方がやりやすくなってくる。
          <br />
          また、「乗せる」扱い方はどのボールでもそれなりにやりやすいが、乗せる質感が全く異なり、各々の趣を見せる。
          <br />
          <br />
          この違いが生まれる理由は、当然だがそれぞれのボールが違った構造を持つからだ。
          <br />
          柔らかい布で小さな粒を包んだもの、プラスチック製の殻に少量の砂を入れたもの、ゴムボールの中にオイルを注入したもの。
          <br />
          彼らは、一般的にボールと言われて想像する道具とはかけ離れた構造を持つ多種多様な道具たちだ。
          <br />
          <br />
          僕は、このボール的な道具たちが体と触れ合うときに、十人十色の反応を返してくれることが楽しくて愛しい。
          <br />
          ４つの扱い方すべてのやりやすさが百点満点のボールは今のところ見当たらない。
          <br />
          理想のボールを追い求めるのは一度切り上げて、ボールによって異なる４つの扱い方への反応に向き合ってみることにした。
          <br />
          また、４つの扱い方のうちの１つ(例えば「乗せる」)がやりやすい道具を探してみたところ、新しいジャグリング道具に出会うこともできた。残りの扱い方には全然向いていないので、なんだかアンバランスで不思議な道具たちだ。
          <br />
          <br />
          今回の作品発表では、それらの成果を音楽に乗せて、浄土の一室で踊ろうと思う。
        </TitleDesc>
        <TitleSub>貫井洋介</TitleSub>
        <TitleDesc>
          1995年生まれ。2012年にジャグリングと出会う。ボールジャグラー。過去には、JJF
          2017 Championship 決勝出場や、Circus without Circle
          第7回公演出演など。
        </TitleDesc>
        <TitleSub>他の参加者</TitleSub>
        <OtherPeople
          link={"/someya"}
          name={"染谷樹"}
          filename={"someya.jpg"}
          link2={"/yamashita_yu"}
          name2={"山下湧志朗"}
          filename2={"yamashita_yu.jpg"}
        />
      </Section>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query {
    images: allFile {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            sizes(maxWidth: 800) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`
const Section = styled.section`
  margin: 40px 0 60px;
  padding: 0 14px;

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    margin: 80px 0 60px;
  }
`

const MainImg = styled(Image)`
  maxwidth: 900;
  height: 275;
  margin: "0 auto";

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    height: 550;
  }
`

const Hr = styled.hr`
  height: 1px;
  background-color: #9a9a9a;
  width: 100%;
  border: none;
`

const TitleMain = styled.h1`
  margin: 8px auto 40px;
  font-size: 2.25rem;
  text-align: center;

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`

const TitleSub = styled.h2`
  margin: 40px auto;
`

const TitleDesc = styled.p`
  margin: 40px 0 20px;
`
