import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Image from "../atoms/image"
import useMedia from "../customHooks/useMedia"

type Props = {
  link: string
  name: string
  filename: string
  link2: string
  name2: string
  filename2: string
}

const OtherPeople: React.FC<Props> = ({
  link,
  name,
  filename,
  link2,
  name2,
  filename2,
}) => {
  return (
    <Flex>
      <Li>
        <ImageLinkWrap>
          <Link to={link}>
            <Image
              filename={filename}
              style={{
                width: 150,
                height: 150,
                borderRadius: "50%",
              }}
            />
          </Link>
        </ImageLinkWrap>
        <Link to={link}>
          <PeopleName>{name}</PeopleName>
        </Link>
      </Li>
      <Li>
        <ImageLinkWrap>
          <Link to={link2}>
            <Image
              filename={filename2}
              style={{
                width: 150,
                height: 150,
                borderRadius: "50%",
              }}
            />
          </Link>
        </ImageLinkWrap>
        <Link to={link2}>
          <PeopleName>{name2}</PeopleName>
        </Link>
      </Li>
    </Flex>
  )
}
export default OtherPeople

const Flex = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: center;

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`

const Li = styled.ul`
  margin-right: 40px;

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    margin-right: 20px;
  }
`

const PeopleName = styled.span`
  font-size: 1.25rem;
  font-weight: 700;
  text-decoration: underline;
  letter-spacing: 0.18rem;

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    display: block;
    text-align: center;
    margin: 14px 0;
  }
`
const ImageLinkWrap = styled.li`
  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    margin: 0 auto;
  }

  cursor: pointer;
  :hover {
    color: white;
    opacity: 0.8;
  }
  :active {
    opacity: 0.8;
  }
`
